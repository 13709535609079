// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: $ENV.BASE_URL || "localhost",
  apiPort: $ENV.API_PORT || 9006,
  authURL: $ENV.AUTH_URL || "https://auth.uat.insuranceconnect.io/api/v1",
  authPort: $ENV.AUTH_PORT || 8080,
  protocol: $ENV.PROTOCOL || "http",
  tenant: $ENV.TENANT || "cmig",
  authStub: $ENV.AUTH_STUB || "true",
  oktaRedirectUrl: $ENV.OKTA_REDIRECT_URL,
  
  LOGIN_URL: $ENV.LOGIN_URL || "https://d-z-insuranceconnect.auth.ca-central-1.amazoncognito.com/login?client_id=6dq8l5f2dc94todifen2e140ei&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback",
  LOGOUT_URL: $ENV.LOGOUT_URL || "https://d-z-insuranceconnect.auth.ca-central-1.amazoncognito.com/logout?client_id=6dq8l5f2dc94todifen2e140ei&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback",
  HOMEPAGE_ROUTE: $ENV.HOMEPAGE_ROUTE || "/ibc/dashboard/",
  REDIRECT_URL: $ENV.REDIRECT_URL,
  TENANT: $ENV.TENANT || "cmig",
  ENVIRONMENT: $ENV.ENVIRONMENT || "development"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
